import React from "react";
// import { Link } from "react-router-dom";

function LoanFec({ uni }) {
  return (
    <div className="w-full py-5 bg-[#f2f2f2] px-4 lg:px-0 flex justify-center">
      <div className="w-[100%] md:w-[90%]">
        <h2 className={`text-[${uni.textColor}] text-[20px] py-2 my-2`} >Education Loan Facility</h2>
        <p>{uni.loanBNK}</p>
        <ul className="pl-0">
          <li className="font-bold">DETAILS</li>
          <li>Contact</li>
          <li>Education Loan Coordinator</li>
          <li>
            Mob : <span>{uni.phone}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LoanFec;
