import React from "react";
import img from "./image/pcet.webp"

function TopBar() {
  return (
    <div className="w-full px-12">
     <img src={img} className="h-[5rem]" />
    </div>
  );
}

export default TopBar;
