import React from "react";
import UniversityPage from "./screen/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";

import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import im1 from "./screen/home/courses/image/Courses/1.png";
import im2 from "./screen/home/courses/image/Courses/2.png";
import im3 from "./screen/home/courses/image/Courses/3.png";
import im4 from "./screen/home/courses/image/Courses/7.png";
import im5 from "./screen/home/courses/image/Courses/8.png";
import im6 from "./screen/home/courses/image/Courses/9.png";
import knmodi1 from "./images/homebanner/jklu.jpg"
import knmodi2 from "./images/homebanner/jklu2.png"

import marwadie2 from "./images/marwadiUniversity/event/1 (2).jpg";
// import marwadie3 from "./images/marwadiUniversity/event/1 (3).jpg";
import marwadie4 from "./images/marwadiUniversity/event/1 (4).jpg";
import marwadie5 from "./images/marwadiUniversity/event/1 (5).jpg";
import marwadie6 from "./images/marwadiUniversity/event/1 (6).jpg";

import devanshithakkr from "./images/raiUniversityAhemdabad/placedStudent/Devanshi-Thakkar-150x150.jpg";
import hillaryshah from "./images/raiUniversityAhemdabad/placedStudent/Hillary-Shah-150x150.jpg";
import niralichristian from "./images/raiUniversityAhemdabad/placedStudent/Nirali-Christian-480x480-min-150x150.jpg";
import sarthakpatel from "./images/raiUniversityAhemdabad/placedStudent/Sarthak-Patel-150x150.jpg";
import twinkeldarji from "./images/raiUniversityAhemdabad/placedStudent/Twinkle-Darji-150x150.jpg";
import virajshah from "./images/raiUniversityAhemdabad/placedStudent/Viraj-Shah-480x480-min-150x150.jpg";
import sagarkamani from "./images/raiUniversityAhemdabad/placedStudent/imgpsh_fullsize_anim-150x150.jpg";
import raih1 from "./images/hiringPartner/4.jpg";
import raih2 from "./images/hiringPartner/5.jpg";
import raih3 from "./images/hiringPartner/Amazon.png";
import raih4 from "./images/hiringPartner/Axis-Bank.png";
import raih5 from "./images/hiringPartner/Tata.png";

import raih7 from "./images/hiringPartner/thumb (6).jpeg";
import raih8 from "./images/hiringPartner/thumb (7).jpeg";


import BlogPost1 from "./screen/blog/blog1";
import BlogPost2 from "./screen/blog/blog2";
import PrivacyPolicy from "./screen/privacyPolicy";
import Navbar from "./screen/home/navbar";
import Enquriyform from "./common/enquiryform/page";
import Footer from "./screen/home/Footer/Footer";
import Disclaimer from "./disclaimer";
import TopBar from "./screen/home/TopBar/TopBar";

const App = () => {

  
  //   university: "pacific University",
  //   image: [karnavati2,karnavati4],
  //   courses: [
  //     {
  //       name: "BBA(Hons)",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         "Marketing",
  //         "Finance",
  //         "HR Management",
  //         "Digital Marketing",
  //         "Aviation",
  //         "Sports Management",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im1, // image source for BBA
  //       description: "Description for BBA course goes here.",
  //     },
  //     {
  //       name: "MBA.",
  //       fee: "1,77,500/Year",
  //       details: ["2 Years", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "Available based on merit and research grants.",
  //       specialisation: [
  //         "Marketing",
  //         "Finance",
  //         "Human Resource (HR)",
  //         "Entrepreneurship And Family Business",
  //         " Data Analytics & Business Intelligence",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im2, // image source for Ph.D.
  //       description: "Description for MBA program goes here.",
  //     },
  //     {
  //       name: "BA LLB (Hons.)",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Business Law",
  //         " IPL (Intellectual Property law)",
  //         "International Law",
  //         "Criminial Law",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im3, // image source for LLM
  //       description: "Description for LLM course goes here.",
  //     },
  //     {
  //       name: "BA (Hons )‐LA",
  //       fee: "2,20,000/Year",
  //       details: ["3+1 Years", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "Available based on merit and financial need.",
  //       specialisation: [
  //         "Political Science & Public Affairs",
  //         "Event Management",
  //         " Accounting and Economics",
  //         "Psychology",
  //         " Journalism",
  //         "Ad & PR",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for BA
  //       description: "Description for BA program goes here.",
  //     },
  //     {
  //       name: "BBA LLB(Hons.)",
  //       fee: "₹ 2,60,000/year",
  //       details: ["5 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Business Law",
  //         "Criminal Law",
  //         " IPL (Intellectual Property law)",
  //         "International Law",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im1, // image source for BBA LLB
  //       description: "Description for BBA LLB course goes here.",
  //     },
  //     {
  //       name: "B.Tech",
  //       fee: "₹ 1,39,250/year",
  //       details: ["4 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         " Computer Science & Engineering",
  //         " CSE -AI & ML",
  //         "CSE- Cyber Security",
  //         " CSE-Data Science",
  //         " Information & Communication Technology",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im7, // image source for B.Tech
  //       description: "Description for B.Tech course goes here.",
  //     },
  //     {
  //       name: "B.S. (Hons) Computer Science",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus","Full Time"],
  //       rating: 4.9,
  //       scholarship: "According to entrance exam results.",
  //       specialisation: [
  //         "Computer Science",
  //         "Data Science",
  //         " Artificial Intelligence & Machine Learning",
  //         "Forensic Science",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for M.Tech
  //       description: "Description for   B.S(Hons) course goes here.",
  //     },
  //     {
  //       name: "BCA",
  //       fee: "₹ 80,000/year",
  //       details: ["3 Year", "On-campus", "Full Time"],
  //       rating: 4.8,
  //       scholarship: "According to merit and need-based.",
  //       specialisation: [
  //         "Computer Applications",
  //         "Software Development",
  //         "Web Development",
  //         "Networking",
  //         "Database Management",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im4, // image source for BCA
  //       description: "Description for BCA course goes here.",
  //     },
  //     {
  //       name: "Integrated‐ B.S. +M.S. Cyber Security",
  //       fee: "₹ 2,20,000/year",
  //       details: ["5 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship:
  //         "According to entrance exam results and academic performance.",
  //       specialisation: [
  //         "Political Science & Public Affairs",
  //         "Event Management",
  //         " Accounting and Economics",
  //         "Psychology",
  //         " Journalism",
  //         "Ad & PR",
  //       ],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im5, // image source for MCA
  //       description: "Description for B.S+M.S course goes here.",
  //     },
  //     {
  //       name: "B.Com (Hons)‐LA ",
  //       fee: "₹ 2,20,000/year",
  //       details: ["3+1 Year", "On-campus", "Full Time"],
  //       rating: 4.9,
  //       scholarship:
  //         "According to entrance exam results and academic performance.",
  //       specialisation: ["Cyber Security"],
  //       location: "Gandhinagar, Gujarat",
  //       approval: true,
  //       image: im8, // image source for MCA
  //       description: "Description for B.S+M.S course goes here.",
  //     },
  //   ],
  //   event: [
  //     {
  //       img: award1,
  //       data: "31",
  //       month: "may, 2023",
  //       hed: "Karnavati University felicitated with EducationWorld Award",
  //       pre: "We are elated to announce that Karnavati University has won the EducationWorld award under the category ‘Campus Design Excellence!",
  //       time: "May 31, 2023",
  //       country: "United Kingdom",
  //       alt: "Augmented Reality Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award2,
  //       data: "25",
  //       month: "jan, 2023",
  //       hed: "4 Star Ranking by Institution’s Innovation Council (IIC)",
  //       pre: "We are happy to announce that Karnavati University has achieved 4 Star Ranking for its continuous efforts to promote Innovation",
  //       time: "January 25, 2023",
  //       country: "United States",
  //       alt: "UX Research Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award4,
  //       data: "21",
  //       month: "May, 2022",
  //       hed: "Top Design Institute of India by Competition Success Review (CSR)",
  //       pre: "Unitedworld Institute of Design, Karnavati University, Gandhinagar, Gujarat has been awarded the CSR Top Design Institute of India by the Chairman of the acclaimed Competition Success Review (CSR), Mr. SK Sachdeva.",
  //       time: "May 21, 2022",
  //       country: "Canada",
  //       alt: "Game Development Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award6,
  //       data: "13",
  //       month: "may , 2019",
  //       hed: "Excellence in Education Award",
  //       pre: "Shri Ritesh Hada, President, Karnavati University, was felicitated by Shri Vijay Rupani, Hon’ble Chief Minister of Gujarat, for extraordinarily exceptional work done",
  //       time: "13 may,2019",
  //       country: "Australia",
  //       alt: "Cloud Security Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award5,
  //       data: "27",
  //       month: "aprail 2019",
  //       hed: "UWSB ranks 24th (A++ Category)",
  //       pre: "Unitedworld School of Business (UWSB) – Ahmedabad Campus ranked 24th (A++ Category) 8th (Industry Interface) 10th (RoI) 10th (West Zone) 10th (Infrastructure) in the annual survey of Siliconindia Education What if not IIMs: B- Schools Survey 2017",
  //       time: "April 27, 2019",
  //       country: "Germany",
  //       alt: "Social Media Marketing Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: award3,
  //       data: "2",
  //       month: "may 2019",
  //       hed: "UID – Best Private Design College in India",
  //       pre: "Unitedworld Institute of Design (UID) has been conferred with the award “Best Private Design College in India” during the National Education Excellence Awards organised by one of the leading Brand Management Consulting & Research firm in India, World Business...",
  //       time: "2 May 2019",
  //       country: "France",
  //       alt: "img",
  //       arrow: <FaArrowRight />,
  //     },
  //   ],
  //   ourPlacedStudent: [
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "I cannot forget my days at Quantum and can’t thank them enough for the wonderful friends that I have made there.",
  //       perImg: qankit,
  //       Name: "Ankit",
  //       work: "Publicis sapient",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "Thanks to the technical skills I got at Quantum, I am today working in one of the top IT companies in India.",
  //       perImg: qarchit,
  //       Name: "Archit Madan",
  //       work: "Adobe",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         " Quantum has not only given me education but has also helped me to build my personality, which helped me to shape my career.",
  //       perImg: qbhupendra,
  //       Name: "Bhupender Pal ",
  //       work: "Oracle",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "I number of companies that visit Quantum for placements is simply amazing. All the students get ample chance to get placed.",
  //       perImg: qdipayan,
  //       Name: "Dipayan Bhowmik",
  //       work: "Adobe",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "Being at Quantum was one of the greatest experiences of my life. I learnt a lot of new things to grow my career.   ",
  //       perImg: qkartikey,
  //       Name: "Karthikey Chauhan",
  //       work: "Capgemini",
  //     },
  //     {
  //       Topimg:
  //         "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
  //       Review:
  //         "It was an Overwhelming experience. I still miss the moments I spent at Quantum. I want to study once more at Quantum. ",
  //       perImg: qmayank,
  //       Name: "Mayank Tayal",
  //       work: "NIIT Technologies",
  //     },
  //   ],
  //   applicationDeadline: "1 july",
  //   noScholarships: "22",
  //   amount: "variable",
  //   internationalStudents: "yes",
  //   scholarshipLink: "www.aapadhe.in",
  //   address: "Gujarat",
  //   loanBNK:
  //     "QUANTUM UNIVERSITY IS UGC APPROVED UNIVERSITY, SO ONCE YOU RECEIVE YOUR JOINING LETTER,YOU CAN CONTACT YOUR BANK FOR EDUCATION LOANS. FOR MORE",
  //   email: "karnawati@offical.com",
  //   phone: "+919257767661",
  //   hiringPartner: [
  //     karah1,
  //     karah2,
  //     karah3,
  //     karah4,
  //     karah5,
  //     karah6,
  //     karah7,
  //     karah8,
  //     karah9,
  //     karah10,
  //     karah11,
  //     karah12,
  //     karah4,
  //     karah7,
  //   ],
  //   studentEnroll: "125,300",
  //   registeredInstructor: "300",
  //   successRate: "100",
  //   successStory: [
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //     "https://www.youtube.com/embed/shu-kJkwKUk?si=LeZ3F-ppgBI8EP3d",
  //     "https://www.youtube.com/embed/Uquu6fNya9Q?si=2G5SBuASTo_FYxTm",
  //     "https://www.youtube.com/embed/LKaEQ1-KZ58?si=FiV_gsTmE0pKiu5C",
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //     "https://www.youtube.com/embed/LKaEQ1-KZ58?si=FiV_gsTmE0pKiu5C",
  //     "https://www.youtube.com/embed/Uquu6fNya9Q?si=2G5SBuASTo_FYxTm",
  //     "https://www.youtube.com/embed/5DGP_NhSMkM?si=_JQ3p_UBNYHT2I8W",
  //   ],
  //   doubtSection: [
  //     {
  //       question: "Is Quantum University good?",
  //       answer1:
  //         "It has featured among the top 10 engineering colleges in uttarakhand. Over the past few years, Quantum University has won the title of best placement college in dehradun,",
  //       answer2:
  //         "due to its latest and innovative teaching-learning methodology. Several reputed companies visit the campus every year for recruiting Q-mates.",
  //     },
  //     {
  //       question: "Is Quantum University degree valid?",
  //       answer1:
  //         "Yes, the degree offered by Quantum University is valid. Quantum University is recognised by the University Grants Commission, which is a statutory body of the Government of India.",
  //     },
  //     {
  //       question: "How old is quantum university?",
  //       answer1:
  //         "History. Quantum University Roorkee was founded in 2017 by industrialist Shri Shyam Sunder Goyal. The core programs initially offered by the college were in the disciplines of Engineering and Management. In 2018 the university started programs in Graduate Studies.",
  //     },
  //     {
  //       question: "Is quantum is UGC approved?",
  //       answer1:
  //         "Quantum University is a private university in Roorkee, Uttarakhand, India. It was established in 2008 and is approved by the University Grants Commission which is a statutory body of the Government of India. Quantum University has both government recognition and professional accreditation.",
  //     },
  //     {
  //       question: "Is Quantum University good for placement?",
  //       answer1:
  //         "Placements: Yes, arrange for placement, and more than 100 students got placed this year alone. A total of 900 students received placement offers from Quantum University in various companies and MNCs.",
  //     },
  //   ],
  //   blogNews: [
  //     {
  //       img: marwadie1,
  //       data: "6",
  //       icon: <FaCalendarAlt />,
  //       month: "6th November 2024",
  //       hed: "Internship Workshop.",
  //       pre: "InternEdge Internship Workshop for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Belgium",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: marwadie2,
  //       data: "30",
  //       icon: <FaCalendarAlt />,
  //       month: "30th December 2024",
  //       hed: "Job Fair.",
  //       pre: "CareerPro Job Fair for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Austria",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //     {
  //       img: marwadie1,
  //       data: "10",
  //       icon: <FaCalendarAlt />,
  //       month: "10th January 2025",
  //       hed: "Career Development Workshop.",
  //       pre: "FutureCare Career Development Workshop for Students of Karnawati University",
  //       txt: "Read More",
  //       country: "Norway",
  //       alt: "Student Image",
  //       arrow: <FaArrowRight />,
  //     },
  //   ],
  // };
  const uni =   {
    id: "Jklu",
    university: "JK Lakshmipat University",
    image:[knmodi1,knmodi2,knmodi1],
    courses: [
      {
        name: "B.Tech",
        fee: "₹ 2,75,000/Annum",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
         location:"Jaipur",
        approval: true,
        image: im6, // image source for B.Tech
        description: "Description for B.Tech course goes here.",
      },
 
    
     
 
      {
        name: "M,tech",
        fee: "₹ 1,95,000/Annum",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          "Software Engineering",
          "Data Science",
          "Information Security",
          "Mobile Applications",
          "Cloud Computing",
        ],
         location:"Jaipur",
        approval: true,
        image: im4, // image source for MCA
        description: "Description for M.tech course goes here.",
      },
  
   
    
      {
        name: " B.Design",
        fee: "₹ according to institution",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.7,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
          "Interdisciplinary Design",
          "Interaction Design",
          "Product Design",
          "Integrated Communication",
          
        ],
         location:"Jaipur",
        approval: true,
        image: im3, // image source for B.Com
        description: "Description for B.Design course goes here.",
      },
      {
        name: " M.Design",
        fee: "₹ according to institution",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.7,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
        
          "Interaction Design",
          "Product Design",
         
          
        ],
         location:"Jaipur",
        approval: true,
        image: im3, // image source for B.Com
        description: "Description for M.Design course goes here.",
      },
      {
        name: "BBA",
        fee: "₹ 1,67,000/Annum",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.7,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
          "Please apply the  form and get to know all the details.",
          "Banking & Fintech",
          "Entrepreneurship",
          "Interdisciplinary",
          
        ],
         location:"Jaipur",
        approval: true,
        image: im6, // image source for B.Com
        description: "Description for BBA course goes here.",
      },
      {
        name: "Phd",
        fee: "please apply and get details",
        details: ["5 Year", "On-campus", "Full Time"],
        rating: 4.7,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
          "Please apply the  form and get to know all the details.",
          "Engineering & Sciences",
          "Management",
          "Humanities",
          
        ],
         location:"Jaipur",
        approval: true,
        image: im6, // image source for B.Com
        description: "Description for Phd course goes here.",
      },
   
     
    ],
    event: [
      {
        img: im1,
        data: "12",
        month: "April, 2026",
        hed: "Blockchain Workshop",
        pre: "Learn about blockchain technology and its real-world applications",
        time: "9:00 am - 4:00 pm",
        country: "United States",
        alt: "Blockchain Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im2,
        data: "25",
        month: "May, 2026",
        hed: "Startup Pitch Competition",
        pre: "Showcase your startup idea and compete for funding and resources",
        time: "10:00 am - 6:00 pm",
        country: "Canada",
        alt: "Startup Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im3,
        data: "8",
        month: "June, 2026",
        hed: "Artificial Intelligence Ethics Seminar",
        pre: "Discuss the ethical implications of AI technology and its impact on society",
        time: "1:00 pm - 7:00 pm",
        country: "Germany",
        alt: "AI Ethics Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie4,
        data: "20",
        month: "July, 2026",
        hed: "Product Management Masterclass",
        pre: "Learn advanced techniques and strategies for product management",
        time: "2:00 pm - 5:00 pm",
        country: "Australia",
        alt: "Product Management Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie5,
        data: "14",
        month: "August, 2026",
        hed: "Content Marketing Workshop",
        pre: "Discover how to create compelling content that drives engagement and conversions",
        time: "9:30 am - 4:30 pm",
        country: "India",
        alt: "Content Marketing Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie6,
        data: "3",
        month: "September, 2026",
        hed: "Frontend Development Bootcamp",
        pre: "Master frontend development skills and build responsive web applications",
        time: "10:00 am - 3:00 pm",
        country: "France",
        alt: "Frontend Development Image",
        arrow: <FaArrowRight />,
      },
    ],
    ourPlacedStudent: [
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "My experience at RU, was nothing short of wonderful! At the beginning, I was unsure about my future and anxious about the different challenges I was going to face. However, the Best College in Ahmedabad gave me the support and confidence to grow both personally and professionally. Thank you, JK Lakshmipat University, for providing me with an unforgettable experience and helping me shape my future. As a student at the Best College in Ahmedabad, I would highly recommend JK Lakshmipat University to anyone seeking a top-quality education that focuses on personal growth and professional development",
        perImg: devanshithakkr,
        Name: "Devanshi Thakker",
        work: "GSBM",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "I am grateful to JK Lakshmipat University – both the faculties and especially the Department of International Affairs. They’ve made efforts ensuring maximum number of students get international exposure through JK Lakshmipat University student exchange programs with various international universities of repute.",
        perImg: hillaryshah,
        Name: "Hillary Shah",
        work: "UIC",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " My life at JK Lakshmipat University, the Best College in Ahmedabad, has been nothing short of breath taking and enriching. The environment at this top-notch educational institution not only helped me imbibe knowledge but also played a vital role in developing my overall personality, fuelling my confidence. JK Lakshmipat University has achieved excellence in imparting high-quality education with a prime focus on holistic learning and inculcating competitive abilities in students, which has helped me immensely.",
        perImg: niralichristian,
        Name: "Nirali Christian",
        work: "Trainee Senior Scientfic Assistance",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "My life at JK Lakshmipat University, the Best College in Ahmedabad, has been nothing short of breath taking and enriching. The environment at this top-notch educational institution not only helped me imbibe knowledge but also played a vital role in developing my overall personality, fuelling my confidence. JK Lakshmipat University has achieved excellence in imparting high-quality education with a prime focus on holistic learning and inculcating competitive abilities in students, which has helped me immensely.",
        perImg: sarthakpatel,
        Name: "Sarthak Patel",
        work: "Executive at Intas Biopharmaceuticals",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "My college experience was more than just going to class, reading textbooks, doing assignments and taking tests. It was about experiencing all that RU has to offer me.",
        perImg: twinkeldarji,
        Name: "Twinkle Darji",
        work: "Pharmacy Technician at Nations first Pharmacy",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "My experience at JK Lakshmipat University, the Best College in Ahmedabad, has been extremely enriching. The exposure to different teaching styles at the Best College in Ahmedabad has given me a unique perspective about life and has helped me identify my true calling. I believe that the education I have received at JK Lakshmipat University has not only prepared me for a successful career but has also helped me become a well-rounded individual. As a student at the Best College in Ahmedabad, I feel proud to be associated with an institution that focuses on holistic learning and provides its students with the best possible education.",
        perImg: virajshah,
        Name: "Viraj Shah",
        work: "UICMR",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Apart from enjoying incredible days with intellectual and lovely amigos, I would like to share that on completion of my graduation at JK Lakshmipat University, the Best College in Ahmedabad, I found myself edified to thrive better in the professional world. The academic programs, sports facilities, and vibrant campus atmosphere gave me an upper hand as a student at a Private University. JK Lakshmipat University truly stands out as the best college in Ahmedabad, providing top-notch education and an enriching college experience.",
        perImg: sagarkamani,
        Name: "Sagar Kamani",
        work: "Researcher at Queensland University of Technology",
      },
    ],
    applicationDeadline: "1 july",
    noScholarships: "22",
    amount: "variable",
    internationalStudents: "yes",
    scholarshipLink: "www.aaopadhe.in",
    address: "Jaipur",
    loanBNK:
      "JK Lakshmipat University HAS ESTABLISHED A COLLABORATION WITH BANK OF BARODA TO FACILITATE ITS STUDENTS WHO ARE INTERESTED IN AVAILING EDUCATION LOANS.",
    email: "raiuni@offical.com",
    phone: "+919358950270",
    hiringPartner: [
      raih1,
      raih2,
      raih3,
      raih4,
      raih5,
      // raih6,
      raih7,
      raih8,
     
    ],
    studentEnroll: "10717",
    registeredInstructor: "400",
    successRate: "100",
    successStory: [
      "https://www.youtube.com/embed/3u77zSKbvzk",
      "https://www.youtube.com/embed/YerrQRpZfD4",
      "https://www.youtube.com/embed/ehRdAexZtIU", 
      "https://www.youtube.com/embed/ySp9PPsCIVw", 
      "https://www.youtube.com/embed/SzEELxdAvzI", 
      "https://www.youtube.com/embed/3u77zSKbvzk",
      "https://www.youtube.com/embed/YerrQRpZfD4",
      "https://www.youtube.com/embed/ehRdAexZtIU", 
      "https://www.youtube.com/embed/ySp9PPsCIVw", 
      "https://www.youtube.com/embed/SzEELxdAvzI", 
    ],
    doubtSection: [
      {
        question: "Is JK Lakshmipat University good gor b.tech?",
        answer1:
          "Tech course in JK Lakshmipat University is amazing and well-designed for students who want to build a career in engineering",
        answer2: null,
      },
      {
        question: "Is JK Lakshmipat University degree valid?",
        answer1:
          "Yes, the degree offered by JK Lakshmipat University is valid. JK Lakshmipat University is recognized by the University Grants Commission, which is a statutory body of the Government of India.",
        answer2: null,
      },
      {
        question: "What is the placement of JK Lakshmipat University?",
        answer1:
          "JK Lakshmipat University provides 100% placement assistance to all of its students. Over the last 9 years, the University has turned up as one of the most favoured destination for hiring fresh talent from the campuses.",
        answer2: null,
      },
      {
        question: "Is JK Lakshmipat University UGC approved?",
        answer1:
          "JK Lakshmipat University | UGC Approved Best College in Ahmedabad Gujarat with best faculties.",
        answer2: null,
      },
      {
        question: "How can I get admission in JK Lakshmipat University?",
        answer1:
          "University admission form can be filled online by clicking Apply Now and can be collected in person from University campus.",
        answer2: null,
      },
    ],
    blogNews: [
      {
        img: marwadie2,
        data: "12",
        icon: <FaCalendarAlt />,
        month: "12th May 2025",
        hed: "Job Recruitment Drive.",
        pre: "RecruitPro Job Recruitment Drive for Students of JK Lakshmipat University",
        txt: "Read More",
        country: "Ireland",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie4,
        data: "21",
        icon: <FaCalendarAlt />,
        month: "21st June 2025",
        hed: "Career Symposium.",
        pre: "CareerForum Career Symposium for Students of JK Lakshmipat University",
        txt: "Read More",
        country: "United Arab Emirates",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie6,
        data: "8",
        icon: <FaCalendarAlt />,
        month: "8th July 2025",
        hed: "Job Fair.",
        pre: "CareerLink Job Fair for Students of JK Lakshmipat University",
        txt: "Read More",
        country: "Qatar",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
    ],
  };
  return (
    <div className="overflow-hidden">
      <Router>
        {/* <TopBar></TopBar> */}
       
        <Navbar uni={uni}></Navbar>
        <Routes>
          {/* Route for the home page */}
          <Route path="/" element={<UniversityPage uni={uni} />} />
          <Route path="/courses" element={<UniversityPage uni={uni} />} />
          <Route path="/apply-now" element={<UniversityPage uni={uni} />} />
          <Route path="/scholarship" element={<UniversityPage uni={uni} />} />
          {/* Route for the enquiry form */}
          <Route path="/enquiryform" element={<Enquriyform />} />
          <Route path="/blogpost2" element={<BlogPost2 />} />
          <Route path="/blogpost1" element={<BlogPost1 />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer uni={uni} />
        <Disclaimer />

        <div className="fixed bottom-0 text-[30px] right-0 gap-3 px-8 py-6 z-[9999]">
        <div className="p-3 bg-gray-300 mb-3 rounded-full">
          <a href ="tel:+919358950270" >
            <IoCall className="text-blue-500" />
          </a>
        </div>
        <div className="p-3 bg-gray-300 mb-2 rounded-full">
          <a href="https://wa.me/+919358950270" >
            <IoLogoWhatsapp className="text-green-500" />
          </a>
        </div>
      </div>
      </Router>
    </div>
  );
};

export default App;
