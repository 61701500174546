import React from "react";
import "./index.css";

const InfiAnimation = ({ uni }) => {
  return (
    <>
      <div className="slider">
        <div className="slide-track">
          {uni &&
            uni.hiringPartner.map((data, index) => {
              console.log(data);
              return (
                <div className="slide px-6 py-3  border-none outline-none" key={index}>
                  <img src={data} width={150} height={150} border-none alt="" />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default InfiAnimation;
